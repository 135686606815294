import React from 'react'
import { HeaderSection, Container, OutlineButton } from '../components'
import 'normalize.css/normalize.css'
import '../styles/common.sass'
import { Link } from 'gatsby'

export default function NotFound() {
  return (
    <>
      <HeaderSection title='Uh Oh' />
      <Container>
        <p>Looks like you've taken a wrong turn. We couldn't find that page.</p>

        <OutlineButton component={Link} dark to='/'>
          Back Home
        </OutlineButton>
      </Container>
    </>
  )
}
