import React, { useContext } from 'react'
import bgimg from '../assets/bgimg-full.jpg'
import cover from '../assets/season1-cover-minimal.png'
import Container from './Container'
import CoverCard from './CoverCard'
import OutlineButton from './OutlineButton'
import { MediaContext } from './MediaPlayer'
import SiteContext from './SiteContext'
import { showSubscribeDialog } from './SubscribeDialog'
import styles from './WelcomeSection.module.sass'

export default function WelcomeSection() {
  const { intro } = useContext(SiteContext)
  const { episodes } = useContext(MediaContext)
  const latestEp = episodes[0]

  const handleSubscribeClick = () => {
    showSubscribeDialog()
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.background}
        style={{
          backgroundImage: `linear-gradient(to bottom, #00000000, #00000050), url('${bgimg}')`
        }}
      />
      <div className={styles.rightAngle} />
      <Container>
        <div className='row row-reverse'>
          <div className={`col ${styles.cardCol}`}>
            <CoverCard episode={latestEp} image={cover} />
          </div>
          <div className='col col-1'>
            <h1>
              IS
              <br />
              THIS
              <br />
              CONFIRMATION?
            </h1>

            <p className={styles.intro}>{intro}</p>
            <div className={styles.buttons}>
              <OutlineButton component='a' href='#episodes'>
                Episodes
              </OutlineButton>
              <OutlineButton onClick={handleSubscribeClick}>
                Listen &amp; Subscribe
              </OutlineButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
