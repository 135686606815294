import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { mdiPause, mdiPlay, mdiLoading } from '@mdi/js'
import IconButton from './IconButton'
import { MediaContext } from './MediaPlayer'

export default function EpisodePlayPauseButton({ episode = {}, ...props }) {
  const { playing, episode: curEp, loading, setPlaying, setEpisode } = useContext(
    MediaContext
  )

  const playingThisEp = curEp && curEp.id === episode.id && playing
  const loadingThisEp = curEp && curEp.id === episode.id && loading
  const handleClick = () => {
    if (curEp && curEp.id === episode.id) {
      setPlaying(!playing)
    } else {
      setEpisode(episode)
      setPlaying(true)
    }
  }

  return (
    <IconButton
      {...props}
      onClick={handleClick}
      spin={loadingThisEp}
      path={loadingThisEp ? mdiLoading : playingThisEp ? mdiPause : mdiPlay}
    />
  )
}

EpisodePlayPauseButton.propTypes = {
  episode: PropTypes.object
}
