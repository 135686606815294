import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import coverImage from '../assets/season1-cover-750px.jpg'
import Container from './Container'
import styles from './EpisodeDetails.module.sass'
import CoverCard from './CoverCard'
import clsx from 'clsx'
import { showSubscribeDialog } from './SubscribeDialog'
import Icon from '@mdi/react'
import { mdiPodcast } from '@mdi/js'

export default function EpisodeDetails({ episode, nextEp, prevEp }) {
  return (
    <Container className={styles.root}>
      <CoverCard
        className={styles.coverCard}
        episode={episode}
        hideMeta
        image={coverImage}
      />
      <div className={styles.info}>
        <span>{episode.date}</span>
        <span>EPISODE {episode.number}</span>
        <span onClick={() => showSubscribeDialog()}>
          <Icon path={mdiPodcast} size='24px' /> SUBSCRIBE
        </span>
      </div>
      <p dangerouslySetInnerHTML={{ __html: episode.html }} />
      <br />
      <br />
      <br />
      <h6 className={styles.heading}>PRODUCERS</h6>
      <p>
        Mesbah Mowlavi
        <br />
        Alanna Petrich
      </p>

      <Link className={clsx(styles.heading, styles.bottomGutter)} to='/'>
        EPISODE LIST
      </Link>
      {prevEp && (
        <Link className={styles.heading} to={'/' + prevEp.slug}>
          PREVIOUS - {prevEp.title}
        </Link>
      )}
      {nextEp && (
        <Link className={styles.heading} to={'/' + nextEp.slug}>
          NEXT - {nextEp.title}
        </Link>
      )}
    </Container>
  )
}

EpisodeDetails.propTypes = {
  episode: PropTypes.shape({
    date: PropTypes.any,
    html: PropTypes.any,
    number: PropTypes.any
  }),
  nextEp: PropTypes.shape({
    slug: PropTypes.any,
    title: PropTypes.any
  }),
  prevEp: PropTypes.shape({
    slug: PropTypes.any,
    title: PropTypes.any
  })
}
