import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './OutlineButton.module.sass'

export default function OutlineButton({
  children,
  className,
  component = 'div',
  dark,
  ...props
}) {
  const El = component
  return (
    <El className={clsx(styles.root, dark && styles.dark, className)} {...props}>
      {children}
    </El>
  )
}

OutlineButton.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  className: PropTypes.string,
  dark: PropTypes.bool
}
