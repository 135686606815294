import React from 'react'
import PropTypes from 'prop-types'
import styles from './CoverCard.module.sass'
import EpisodePlayPauseButton from './EpisodePlayPauseButton'
import clsx from 'clsx'

export default function CoverCard({ className, episode = {}, hideMeta, image }) {
  return (
    <div className={clsx(styles.root, className)}>
      <img src={image} alt={episode.title} />
      {!hideMeta && (
        <div className={styles.caption}>
          <span>
            EPISODE {episode.number}: {episode.title}
          </span>
          <EpisodePlayPauseButton episode={episode} clear size='small' />
        </div>
      )}
    </div>
  )
}

CoverCard.propTypes = {
  className: PropTypes.string,
  episode: PropTypes.object,
  hideMeta: PropTypes.bool,
  image: PropTypes.string
}
