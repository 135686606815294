import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './EpisodesSection.module.sass'
import Container from './Container'
import EpisodePlayPauseButton from './EpisodePlayPauseButton'
import { Link } from 'gatsby'
import HeaderSection from './HeaderSection'
import LinkIcons from './LinkIcons'
import SiteContext from './SiteContext'
import { MediaContext } from './MediaPlayer'

function EpisodeEntry({ episode }) {
  return (
    <div className={styles.episode}>
      <EpisodePlayPauseButton
        episode={episode}
        size='large'
        className={styles.playPause}
      />
      <h4>{episode.title}</h4>
      <p>
        {episode.excerpt} <Link to={episode.slug}>Read more...</Link>
      </p>
      <div className={styles.info}>
        <span>{episode.date}</span>
        <span>EPISODE {episode.number}</span>
      </div>
    </div>
  )
}
EpisodeEntry.propTypes = {
  episode: PropTypes.shape({
    date: PropTypes.string,
    html: PropTypes.string,
    excerpt: PropTypes.string,
    number: PropTypes.any,
    title: PropTypes.string,
    slug: PropTypes.string
  })
}

export default function EpisodesSection() {
  const { links } = useContext(SiteContext)
  const { episodes } = useContext(MediaContext)

  return (
    <>
      <HeaderSection title='EPISODES' anchor='episodes'>
        <LinkIcons links={links} className={styles.links} />
      </HeaderSection>
      <Container>
        {episodes.map((ep, i) => (
          <EpisodeEntry key={i} episode={ep} />
        ))}
      </Container>
    </>
  )
}
