import React from 'react'
import PropTypes from 'prop-types'
import { mdiSpotify, mdiGooglePodcast, mdiApple, mdiSoundcloud } from '@mdi/js'
import IconButton from './IconButton'

const ICONS = {
  spotify: mdiSpotify,
  google: mdiGooglePodcast,
  apple: mdiApple,
  soundcloud: mdiSoundcloud
}

export default function LinkIcons({ className, links }) {
  const buttons = []

  for (let i = 0; i < links.length; i++) {
    const link = links[i]
    if (!link || !link.type || !ICONS[link.type]) continue
    buttons.push(
      <IconButton
        key={i}
        clear
        component='a'
        href={link.url}
        title={link.name}
        target='_blank'
        rel='noreferrer'
        path={ICONS[link.type]}
      />
    )
  }

  return <div className={className}>{buttons}</div>
}

LinkIcons.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array
}
