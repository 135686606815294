import React, { useContext } from 'react'
import Container from './Container'
import styles from './QuoteSection.module.sass'
import SiteContext from './SiteContext'

export default function QuoteSection() {
  const { quote: { text, citation } = {} } = useContext(SiteContext)

  return (
    <Container className={styles.root}>
      <div className={styles.text}>
        {text}
        <div className={styles.citation}>{citation}</div>
      </div>
    </Container>
  )
}
