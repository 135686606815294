import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import bgimg from '../assets/bgimg-full.jpg'
import Container from './Container'
import styles from './HeaderSection.module.sass'

export default function HeaderSection({
  anchor,
  children,
  className,
  level = 2,
  title,
  titleChildren
}) {
  const Component = 'h' + level

  return (
    <div
      className={clsx(styles.root, className)}
      style={{ backgroundImage: `url('${bgimg}')` }}
    >
      {anchor && <a name={anchor} />}
      <Container className={styles.container}>
        <Component className={styles.title}>
          {titleChildren}
          {title}
        </Component>
        {children}
      </Container>
    </div>
  )
}

HeaderSection.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  level: PropTypes.number,
  title: PropTypes.string,
  titleChildren: PropTypes.any
}
